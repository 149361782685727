import styled from 'styled-components'

import {Row} from "antd";

export const StyledSection7Container = styled.div`
  position: relative;
  background-color: #363636;
  
  @media (max-width: 991px){
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;
    
    .title{
      color: #1D1D1F;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      font-weight: bold;
    }
    
    .subtitle{
      color: #1D1D1F;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }
  
  .container7{
    padding-top: 25px;
    padding-bottom: 25px;
    
    
    .buttonsContainer{
      justify-content: flex-end;
        
        @media(max-width: 768px){
            justify-content: center;
        }
    }
  }
  
  .ant-btn{

    @media (max-width: 1663px) {
      height: 50px;
    }

    @media (max-width: 1609px) {
      margin-top: 8px;
    }
    
    @media (max-width: 991px){
      
      margin-bottom: 8px;
    }

    @media (max-width: 450px) {
      width: 300px !important;
      height: 48px;
    }
  }
  
  @media(max-width: 1715px){
    .ant-btn{
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
  
  .phoneContainer{
    @media (max-width: 1573px) {
      margin-right: 8px;
    }

    @media (max-width: 1500px) {
      margin-right: 0 !important;
    }
  }

  .section7Container {
    margin-bottom: 32px;
    text-align: end;

    @media(max-width: 1715px){
      text-align: end !important;
      .section7Text{
        font-size: 26px;
      }
    }

      @media(max-width: 1280px){
        text-align: end !important;
          .section7Text{
            font-size: 22px;
          }
      }

      @media(max-width: 800px){
          .section7Text{
              font-size: 20px;
          }
      }

    @media (max-width: 991px) {
      margin-bottom: 16px;
    }

    @media(max-width: 780px){
      .section7Text{
        font-size: 20px;
        line-height: 28px;
      }
    }

      @media(max-width: 768px){
          text-align: center !important;
      }
      
    @media (max-width: 450px){
      padding-top: 32px;
      padding-bottom: 32px;
        
        .section7Text{
            font-size: 20px
        }
    }
  }
  
  .section7Text{
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;
    color: #FFF;
    
    @media (min-width: 769px) and (max-width: 991px){
      font-size: 28px;
      
      margin-bottom: 16px;
    }

    @media (max-width: 768px){
      font-size: 24px;

      margin-bottom: 16px;
    }

    @media (max-width: 450px){
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 0px;

    }

    @media (max-width: 400px){
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 0px;
      
    }
  }
  
`

export const StyledContactContainer = styled(Row)`
  border-radius: 40px;
  background-color: #fff;
  padding: 12px 16px;
  
  
  @media (max-width: 1663px) {
    padding: 9px 8px;
  }
  
  .anticon{
    margin-right: 12px;
  }
  
  span{
    font-size: 24px;
    line-height: 31px;
    color: #1D1D1F;
    
    @media(max-width: 1600px){
      font-size: 22px;
    }

    @media(max-width: 1280px){
      font-size: 16px;
    }
      
    @media (max-width: 400px){
      font-size: 16px;
      line-height: 28px;
    }


      @media (max-width: 350px){
          font-size: 14px;
          line-height: 26px;
      }
  }
  
  @media (max-width: 991px){
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  @media (max-width: 450px){
    padding: 8px 6px;
    margin-right: 0;

    .anticon{
      margin-right: 8px;
    }
  }
`