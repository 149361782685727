import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.9185 8H1.08153C0.499167 8 0 8.49376 0 9.06982V39.9302C0 40.5062 0.499167 41 1.08153 41H48.9185C49.5008 41 50 40.5062 50 39.9302V9.06982C49.9168 8.49376 49.4176 8 48.9185 8ZM47.8369 38.8603H2.07987V10.0574H47.8369V38.8603Z" fill="white"/>
      <path d="M35.3577 36.803H43.6772C44.2596 36.803 44.7587 36.3092 44.7587 35.7332V13.1845C44.7587 12.6085 44.2596 12.1147 43.6772 12.1147H35.3577C34.7754 12.1147 34.2762 12.6085 34.2762 13.1845V35.8155C34.3594 36.3092 34.7754 36.803 35.3577 36.803ZM36.4393 14.1721H42.6789V34.7456H36.4393V14.1721Z" fill="white"/>
      <path d="M7.32113 35.8155C7.90349 35.8155 8.40266 35.3217 8.40266 34.7456V14.1721C8.40266 13.596 7.90349 13.1022 7.32113 13.1022C6.73877 13.1022 6.2396 13.596 6.2396 14.1721V34.7456C6.2396 35.3217 6.73877 35.8155 7.32113 35.8155Z" fill="white"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
