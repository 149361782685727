import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" transform="matrix(-1 0 0 1 48 0)" fill="white"/>
    <path
      d="M24.0001 25.4658L17.7697 31.6959C17.3645 32.1014 16.7092 32.1014 16.3039 31.6959C15.8987 31.2907 15.8987 30.6354 16.3039 30.2302L22.5343 23.9999L16.3039 17.7698C15.8987 17.3644 15.8987 16.7093 16.3039 16.3041C16.7092 15.8986 17.3645 15.8986 17.7697 16.3041L24.0001 22.5342L30.2305 16.3041C30.6358 15.8986 31.291 15.8986 31.6963 16.3041C31.8984 16.506 32 16.7716 32 17.037C32 17.3023 31.8984 17.5677 31.6963 17.7698L25.4659 23.9999L31.6963 30.2302C31.8984 30.4323 32 30.6977 32 30.963C32 31.2284 31.8984 31.4938 31.6963 31.6959C31.291 32.1014 30.6358 32.1014 30.2305 31.6959L24.0001 25.4658Z"
      fill="#2F2E2E"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
