import Icon from "@ant-design/icons";


const Svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="186" height="180" viewBox="0 0 186 180" fill="none">
        <path opacity="0.2" d="M46.0941 169.889L18.3479 153.9L0.8302 164.011L28.4588 180L46.0941 169.889Z"
              fill="#00549D"/>
        <path opacity="0.2"
              d="M60.3187 127.798C62.67 126.623 65.139 125.564 67.6079 124.741L128.391 152.135L185.999 118.863L126.862 84.7681L71.958 118.04C65.139 117.687 58.2024 117.217 51.3835 116.864C48.2091 116.629 44.7996 116.512 41.7428 117.335C38.9212 118.158 36.4523 119.686 33.9833 121.214C29.163 124.271 24.3427 128.386 21.8738 133.794C20.8156 135.91 20.6981 138.615 21.9913 140.613C22.6967 141.789 23.8724 142.612 24.9305 143.317C27.5171 145.081 29.986 146.962 32.5725 148.725C34.6887 150.254 36.805 151.665 39.1563 152.605C42.801 154.016 46.7983 153.781 50.6781 153.663C54.9105 153.546 59.143 153.311 63.2579 152.37C65.4917 151.9 67.7255 151.077 70.0769 150.724C72.8985 150.136 75.8377 150.136 78.6594 150.019C83.3621 149.901 88.0648 149.431 92.65 148.961C93.8257 148.843 95.119 148.49 95.4717 147.432C95.7068 146.374 94.7663 145.434 93.8257 144.846C89.946 142.612 85.1256 143.317 80.658 143.317C72.4282 143.2 63.9633 139.438 59.2606 132.736C58.6727 131.796 58.0849 130.737 58.4376 129.679C58.5551 128.856 59.4957 128.269 60.3187 127.798Z"
              fill="#00549D"/>
        <path
            d="M61.9655 74.3051L66.0804 77.2443C72.6642 77.2443 86.1846 78.0673 86.1846 78.0673V82.77C76.6615 85.7092 65.7277 85.8268 61.7303 84.4159C60.6722 84.0632 59.379 83.593 58.0857 83.0051C60.6722 81.2416 61.9655 77.8321 61.9655 74.3051Z"
            fill="#FFB294"/>
        <path
            d="M82.1894 83.5916L87.3624 84.297L92.4178 84.6497L97.5908 83.2389C98.649 82.8861 98.8841 82.1807 97.826 82.2983L95.357 82.5334L99.3544 81.1226C101.118 80.5348 100.883 79.7118 99.7071 79.7118L98.2963 79.8294L100.648 79.0064C102.058 78.6537 101.823 77.7131 100.765 77.5956L94.0638 78.1834L100.295 76.5374C101.353 76.1847 101.118 75.2442 99.9422 75.2442L90.4192 76.5374L88.6556 75.4793L91.3597 72.4225C92.0651 71.5996 91.4773 70.659 90.5367 71.482C89.361 72.5401 87.9502 73.4807 86.5394 74.1861L83.1299 78.4185L80.0731 80.5348L78.5447 82.0632L82.1894 83.5916Z"
            fill="#E68660"/>
        <path d="M128.393 59.9598V148.254L75.7224 117.921V29.5096L128.393 59.9598Z" fill="#779CBF"/>
        <path d="M75.7224 29.5098L128.393 59.96L179.653 30.3327L126.982 6.10352e-05L75.7224 29.5098Z" fill="#F0FAFF"/>
        <path d="M179.652 30.3339V118.628L128.392 148.255V59.9612L179.652 30.3339Z" fill="white"/>
        <path
            d="M165.155 99.5011C172.844 89.2782 174.408 77.4788 168.647 73.1464C162.887 68.8141 151.985 73.5894 144.296 83.8123C136.607 94.0352 135.043 105.835 140.803 110.167C146.563 114.499 157.466 109.724 165.155 99.5011Z"
            fill="white"/>
        <path
            d="M166.372 100.919C174.061 90.6959 175.624 78.8965 169.864 74.5641C164.104 70.2318 153.202 75.007 145.513 85.23C137.824 95.4529 136.26 107.252 142.02 111.585C147.78 115.917 158.683 111.142 166.372 100.919Z"
            fill="#8ABDF1"/>
        <path
            d="M142.853 100.64C142.853 101.933 143.088 103.226 143.441 104.167C145.087 108.517 150.025 109.575 155.903 106.166C163.075 102.051 168.953 92.7629 168.953 85.5912C168.953 79.9479 165.426 77.2438 160.488 78.1844C159.078 78.4195 157.549 79.0074 155.903 79.9479C148.614 84.1804 142.853 93.3507 142.853 100.64Z"
            fill="#182939"/>
        <path
            d="M142.853 100.64C142.853 101.934 143.088 103.227 143.441 104.167C145.557 105.696 148.967 105.578 152.846 103.344C159.313 99.5823 164.603 91.2349 164.603 84.7687C164.603 81.2416 163.075 78.8902 160.606 78.1848C159.195 78.42 157.667 79.0078 156.021 79.9483C148.614 84.1808 142.853 93.3512 142.853 100.64Z"
            fill="#051117"/>
        <path opacity="0.75"
              d="M153.788 103.815C154.023 103.815 154.258 103.697 154.494 103.579C160.842 99.9347 166.133 91.5874 166.133 85.4738C166.133 84.6508 165.545 84.063 164.722 84.063C163.899 84.063 163.311 84.6508 163.311 85.4738C163.311 90.5293 158.491 98.0536 153.083 101.11C152.377 101.463 152.142 102.404 152.612 103.109C152.73 103.579 153.2 103.815 153.788 103.815Z"
              fill="white"/>
        <path opacity="0.2"
              d="M148.144 97.2305C148.732 97.2305 149.32 96.8778 149.437 96.2899C150.496 93.5858 152.142 90.8818 154.258 88.648C154.846 88.0601 154.728 87.1196 154.14 86.6493C153.552 86.0615 152.612 86.179 152.142 86.7669C149.79 89.3534 147.909 92.4102 146.733 95.3494C146.498 96.0548 146.851 96.8778 147.556 97.2305C147.791 97.1129 147.909 97.2305 148.144 97.2305Z"
              fill="white"/>
        <path opacity="0.5"
              d="M158.253 50.3202C158.841 49.9675 159.312 49.8499 159.782 49.7323C160.487 49.6148 161.075 49.7323 161.545 49.9675L161.663 50.085C161.781 50.085 161.781 50.2026 161.898 50.2026L163.427 51.3783L162.604 52.554C162.486 54.9054 160.605 57.8446 158.253 59.2554C158.136 59.373 158.018 59.373 157.783 59.4905L156.725 61.0189L154.726 59.4905C154.491 59.2554 154.256 58.9027 154.021 58.55C153.903 58.1973 153.786 57.8446 153.786 57.3743C153.903 54.7878 155.785 51.731 158.253 50.3202Z"
              fill="#5EA6E9"/>
        <path
            d="M155.784 58.667C155.784 59.1373 155.902 59.49 156.019 59.8427C156.607 61.3711 158.253 61.6062 160.252 60.5481C162.721 59.1373 164.719 56.0805 164.719 53.6116C164.719 51.7305 163.544 50.7899 161.898 51.1426C161.427 51.2602 160.839 51.4953 160.369 51.7305C157.665 53.1413 155.784 56.1981 155.784 58.667Z"
            fill="#182939"/>
        <path
            d="M169.186 41.7367C169.421 41.6191 169.656 41.5015 169.891 41.5015C170.244 41.3839 170.479 41.5015 170.714 41.6191C170.832 41.6191 170.832 41.7367 170.832 41.7367L171.537 42.2069L171.185 42.6772C171.067 43.7353 170.244 45.0286 169.303 45.6164C169.186 45.6164 169.186 45.734 169.068 45.734L168.598 46.4394L167.658 45.734C167.54 45.6164 167.422 45.4989 167.305 45.2637C167.305 45.1461 167.187 44.911 167.187 44.7934C167.187 43.7353 168.01 42.3245 169.186 41.7367Z"
            fill="white"/>
        <path
            d="M168.01 45.381C168.01 45.6161 168.01 45.7337 168.128 45.8512C168.363 46.5566 169.069 46.6742 170.009 46.2039C171.067 45.6161 172.008 44.2053 172.008 43.1472C172.008 42.3242 171.538 41.8539 170.715 42.089C170.479 42.089 170.244 42.2066 170.009 42.3242C168.833 43.0296 168.01 44.3228 168.01 45.381Z"
            fill="#779CBF"/>
        <path
            d="M174.595 38.6805C174.83 38.5629 175.065 38.4454 175.301 38.4454C175.653 38.3278 175.888 38.4454 176.123 38.5629C176.241 38.5629 176.241 38.6805 176.241 38.6805L176.946 39.1508L176.594 39.6211C176.476 40.6792 175.653 41.9724 174.713 42.5603C174.595 42.5603 174.595 42.6778 174.478 42.6778L174.007 43.3833L173.067 42.6778C172.949 42.5603 172.832 42.4427 172.714 42.2076C172.714 42.09 172.596 41.8549 172.596 41.7373C172.714 40.6792 173.537 39.2684 174.595 38.6805Z"
            fill="white"/>
        <path
            d="M173.537 42.3248C173.537 42.5599 173.537 42.6775 173.654 42.7951C173.89 43.5005 174.595 43.6181 175.535 43.1478C176.594 42.5599 177.534 41.1491 177.534 40.091C177.534 39.268 177.064 38.7977 176.241 39.0329C176.006 39.0329 175.771 39.1505 175.535 39.268C174.36 39.8559 173.537 41.2667 173.537 42.3248Z"
            fill="#779CBF"/>
        <path d="M150.259 53.4948L132.036 63.9584V75.9504L150.259 65.4868V53.4948Z" fill="#182939"/>
        <path opacity="0.5" d="M179.652 101.815V118.627L128.392 148.255V131.442L179.652 101.815Z" fill="#EDEDED"/>
        <path d="M134.388 66.0743L140.149 62.7824V65.7216L134.388 69.0135V66.0743Z" fill="white"/>
        <path opacity="0.5" d="M171.893 47.497L168.601 49.4957V52.0822L171.893 50.0835V47.497Z" fill="#5EA6E9"/>
        <path d="M173.068 46.7939V49.3804L176.36 47.4993V44.9128L173.068 46.7939Z" fill="#182939"/>
        <path opacity="0.5" d="M171.893 51.6127L168.601 53.4938V56.0803L171.893 54.1992V51.6127Z" fill="#5EA6E9"/>
        <path d="M173.068 50.9075V53.494L176.36 51.6129V49.0264L173.068 50.9075Z" fill="#779CBF"/>
        <path opacity="0.5" d="M171.893 55.6099L168.601 57.6086V60.1951L171.893 58.1964V55.6099Z" fill="#5EA6E9"/>
        <path d="M173.068 55.0231V57.6096L176.36 55.7285V53.142L173.068 55.0231Z" fill="#779CBF"/>
        <path d="M171.893 59.7255L168.601 61.7242V64.3107L171.893 62.312V59.7255Z" fill="white"/>
        <path d="M173.068 59.0204V61.607L176.36 59.7258V57.1393L173.068 59.0204Z" fill="white"/>
        <path
            d="M157.549 58.5492C157.549 58.9019 157.549 59.1371 157.667 59.3722C158.02 60.4303 159.313 60.6654 160.724 59.8425C162.487 58.7844 163.898 56.6681 163.898 54.9046C163.898 53.6113 163.075 52.9059 161.9 53.1411C161.547 53.2586 161.194 53.3762 160.841 53.6113C158.96 54.5519 157.549 56.7857 157.549 58.5492Z"
            fill="#CAE5FC"/>
        <path d="M125.335 63.2526V141.553L78.7783 114.63V36.2119L125.335 63.2526Z" fill="#182939"/>
        <path d="M121.81 67.6036V146.022L75.1356 125.095V46.794L121.81 67.6036Z" fill="white"/>
        <path
            d="M40.9225 139.319C38.9238 141.553 38.8063 145.903 39.3941 148.019C41.8631 149.548 44.0969 150.135 45.8604 149.43C51.0334 150.135 53.7375 151.311 56.4416 151.076C59.3808 150.841 61.3794 150.371 63.3781 147.902C64.2011 146.844 63.8484 144.375 61.6146 143.669C57.3821 142.729 53.7375 141.671 51.8564 140.26L49.505 133.088L41.9806 136.968L40.9225 139.319Z"
            fill="black"/>
        <path
            d="M37.2771 127.21L41.8623 131.678C41.8623 131.678 46.2123 133.324 43.861 136.028C42.6853 137.321 39.3934 137.674 39.3934 137.674C36.219 137.557 37.1596 137.792 30.6933 132.619L26.5784 131.208C24.3446 130.385 22.8162 128.974 22.8162 126.505L23.1689 124.859L26.6959 118.863L29.8703 118.981L37.2771 121.097V127.21Z"
            fill="black"/>
        <path
            d="M27.5193 121.214L35.2789 122.978C38.8059 122.625 41.9803 122.037 44.8019 121.45C47.7411 120.744 50.3276 120.039 52.4439 119.216C65.8467 114.043 65.7291 106.283 65.7291 106.283C63.7305 99.5818 43.9789 97.9358 36.9248 97.5831C33.8681 97.348 31.8694 97.348 31.8694 97.348C32.3397 96.9953 27.6369 120.627 27.5193 121.214Z"
            fill="#0C2136"/>
        <path
            d="M61.6136 128.034L51.15 140.496C51.15 140.496 45.977 143.2 40.6864 139.438C41.0391 135.676 42.9202 131.561 45.154 128.269C47.3878 125.094 49.9743 122.508 51.8554 121.685C52.0905 121.567 52.2081 121.567 52.2081 121.567C52.0905 121.567 45.2716 124.389 37.6296 125.917C33.985 126.623 30.1052 126.975 26.5782 126.623C19.0538 125.682 17.643 116.629 17.5254 110.398C17.5254 106.871 17.8781 104.285 17.8781 104.285L29.5174 106.048L31.6336 106.401C31.6336 106.401 32.2215 106.401 33.162 106.519C33.7498 106.519 34.3377 106.636 35.1607 106.754C36.8066 106.871 38.8053 107.106 41.1567 107.459C45.6243 108.164 50.7973 109.223 55.3825 110.986C58.792 112.279 61.8487 114.043 63.8474 116.277C64.5528 117.1 65.2582 118.04 65.6109 118.981C65.6109 118.981 66.5515 123.684 61.6136 128.034Z"
            fill="#214B71"/>
        <path
            d="M55.7354 69.6019L62.0841 74.187C62.0841 77.8317 60.7908 81.3587 58.0867 83.2398C55.03 81.9466 51.5029 80.3006 47.8583 78.4195L55.7354 69.6019Z"
            fill="white"/>
        <path
            d="M50.7971 90.1761C50.3268 90.9991 49.6214 91.8221 48.916 92.5275L48.4457 93.9383L47.27 95.5843L43.1551 103.932L41.0389 107.459C40.9213 107.576 40.451 107.811 39.628 108.282C36.6888 109.81 29.8698 113.102 23.6387 112.632C21.4049 112.514 19.2887 111.809 17.4076 110.515C17.29 109.105 15.8792 94.4086 22.463 84.2977C29.282 73.7165 35.7483 61.3718 51.7376 67.603C51.7376 67.603 51.9727 68.4259 52.3254 69.7192C53.6187 73.9517 54.9119 83.4747 50.7971 90.1761Z"
            fill="#214B71"/>
        <path
            d="M32.2217 84.2979C32.2217 84.2979 38.688 77.8316 45.1542 80.6533C50.0921 82.8871 51.1502 88.2952 51.3854 90.7642C55.5003 84.0628 53.6192 73.8343 52.4435 69.4843C48.211 66.3099 42.215 66.5451 40.569 67.6032C35.6312 71.7181 33.0447 77.9492 32.2217 84.2979Z"
            fill="white"/>
        <path
            d="M48.9161 94.1729L43.2728 103.696C36.8066 104.401 32.1038 105.107 30.1052 100.051C30.1052 100.051 30.1052 100.051 30.9281 93.1147C31.7511 86.1782 37.512 80.77 44.2134 83.239C48.5634 84.8849 49.0337 90.5282 48.9161 94.1729Z"
            fill="white"/>
        <path
            d="M60.9075 74.3049C60.79 74.5401 53.7358 83.1226 53.7358 83.1226C53.7358 83.1226 48.2101 78.655 48.5628 70.3076C49.9736 68.5441 51.1493 67.6035 53.6183 65.017L60.9075 74.3049Z"
            fill="#FFB294"/>
        <path
            d="M51.5013 66.3106C51.5013 66.3106 50.4431 57.1402 59.1432 54.5537C59.1432 54.5537 69.3717 53.2604 71.4879 61.9605C71.4879 61.9605 72.1933 67.6038 69.6068 70.0727L69.7244 72.0714C69.7244 72.5417 69.4893 73.012 69.019 73.2471L68.0784 73.7174L66.4325 76.4215C65.8446 77.4796 64.6689 78.185 63.4933 78.185C62.3176 78.3026 60.7892 78.0674 59.1432 77.0093C55.2635 74.7755 51.5013 66.3106 51.5013 66.3106Z"
            fill="#FFB294"/>
        <path
            d="M71.3717 65.8393C69.4906 68.0731 63.6122 66.5447 61.8486 70.3069C61.8486 70.3069 58.9094 74.1867 55.0297 71.7177C51.0323 69.2488 50.7972 67.3677 50.7972 67.3677C50.7972 67.3677 50.2094 57.8446 55.7351 55.1406C60.7905 52.6716 64.7879 53.9649 67.9622 56.0811C71.1366 58.0798 74.1933 62.4298 71.3717 65.8393Z"
            fill="black"/>
        <path
            d="M60.9078 68.5448C60.5551 68.5448 60.2024 68.78 59.8497 69.0151C59.0267 69.7205 58.5564 70.7786 58.5564 71.8368C58.5564 72.307 58.7915 72.8949 59.2618 73.13C59.6145 73.3651 59.9672 73.2476 60.3199 73.13C60.6726 73.0124 60.9078 72.6597 61.1429 72.4246C61.6132 71.8368 62.3186 70.8962 62.3186 70.0732C62.4362 69.2502 61.8483 68.4273 60.9078 68.5448Z"
            fill="#FFB294"/>
        <path opacity="0.5"
              d="M63.8473 116.159L60.5554 116.629L47.2701 118.51L26.4605 112.984L23.874 112.279C26.3429 110.868 28.224 108.517 29.7524 105.813C33.0443 99.699 34.1025 92.4098 34.1025 92.4098L39.5106 97.9355L48.3283 95.4666L43.3904 103.814L41.2741 107.341C45.6242 108.046 50.9148 109.105 55.4999 110.986C58.7919 112.279 61.8486 113.925 63.8473 116.159Z"
              fill="#214B71"/>
        <path
            d="M45.9776 105.695C49.8573 104.872 53.3844 102.05 54.5601 98.1705L60.7912 108.869L82.5414 107.929L82.8941 113.69C82.8941 113.69 69.3737 118.627 61.7318 117.334C61.7318 117.334 57.4993 117.452 50.4452 110.045L45.9776 105.695Z"
            fill="#FFB294"/>
        <path
            d="M46.094 106.283L37.0413 97.5825C27.9885 88.6473 40.8034 77.9485 47.9751 87.7067L55.6171 98.9933C54.4414 103.108 50.0914 105.342 46.094 106.283Z"
            fill="white"/>
        <path
            d="M79.6005 113.573L84.6559 114.749L89.7114 115.572L95.002 114.631C96.1777 114.279 96.4128 113.691 95.3547 113.691L92.8857 113.808L97.0006 112.75C98.7642 112.28 98.6466 111.574 97.4709 111.457H96.0601L98.4115 110.869C99.8223 110.634 99.7047 109.693 98.6466 109.576H91.9452L98.2939 108.518C99.4696 108.283 99.2344 107.342 98.0588 107.224L88.4181 107.695L86.7722 106.401L89.7114 103.58C90.4168 102.874 89.9465 101.816 88.8884 102.639C87.5952 103.58 86.1843 104.403 84.6559 104.991L82.4221 108.047L77.6018 110.751L75.9559 112.162L79.6005 113.573Z"
            fill="#FFB294"/>
        <path
            d="M91.0072 159.777L90.5369 158.484L90.4193 158.366C90.3018 158.366 90.1842 158.249 90.0666 158.366L88.5382 158.602C88.4207 158.602 88.4207 158.602 88.4207 158.602L88.6558 159.307V160.13C88.6558 160.248 88.4207 160.365 88.3031 160.365C87.9504 160.365 87.4801 160.248 87.245 160.012C86.5396 159.66 86.0693 159.072 85.9517 158.484V158.014C85.8342 157.896 85.7166 157.779 85.599 157.661L73.8422 151.783C73.607 151.665 73.1368 151.547 72.7841 151.665C71.9611 151.783 71.1381 151.665 70.4327 151.312C70.3151 151.195 70.1976 151.195 70.08 151.077C69.7273 150.842 69.4921 150.372 69.3746 149.901V149.078C69.3746 148.608 69.6097 148.02 70.1975 147.668C70.3151 147.55 70.5503 147.55 70.6678 147.668C70.6678 147.668 70.7854 147.668 70.7854 147.785L71.4908 149.078L71.6084 149.196C71.7259 149.196 71.8435 149.314 72.0786 149.196L73.607 148.843L73.2543 148.138V147.315C73.2543 147.197 73.3719 147.08 73.607 147.08C74.0773 147.197 74.43 147.315 74.7827 147.433C75.4881 147.785 75.9584 148.373 75.9584 148.961V149.549C76.076 149.666 76.1936 149.784 76.3111 149.901L87.9504 155.78C88.1855 155.897 88.6558 156.015 89.0085 155.897C89.5964 155.897 90.1842 155.897 90.7721 156.015C91.0072 156.133 91.2423 156.133 91.4775 156.25C92.1829 156.603 92.418 157.073 92.5356 157.661V158.484C92.5356 159.072 92.1829 159.66 91.595 160.012C91.3599 159.777 91.1248 159.777 91.0072 159.777Z"
            fill="#4D4D4D"/>
        <path
            d="M87.1257 159.189C87.4784 159.307 87.8311 159.425 88.1838 159.542C88.419 159.542 88.6541 159.425 88.5366 159.307L88.0663 158.014C88.0663 157.896 88.1839 157.779 88.419 157.661L89.9474 157.426C90.0649 157.426 90.1825 157.426 90.3001 157.426C90.4176 157.426 90.4177 157.543 90.4177 157.543L90.8879 158.837C90.8879 158.837 90.8879 158.954 91.0055 158.954C91.1231 158.954 91.3582 159.072 91.4758 158.954C92.6515 158.131 92.769 156.838 91.3582 156.133C91.1231 156.015 91.0055 155.898 90.6528 155.898C90.0649 155.78 89.4771 155.662 88.8893 155.78C88.5366 155.78 88.0663 155.78 87.8312 155.662L76.1919 149.784C75.8391 149.666 75.7216 149.431 75.7216 149.079C75.8392 148.373 75.3689 147.785 74.5459 147.315C74.1932 147.08 73.7229 146.962 73.3702 146.962C73.1351 146.962 72.8999 147.08 73.0175 147.197L73.7229 148.373C73.8405 148.491 73.7229 148.608 73.4878 148.726L71.9594 149.079C71.8418 149.079 71.6067 149.079 71.4891 149.079L71.3715 148.961L70.6661 147.668C70.6661 147.668 70.6661 147.668 70.5486 147.55C70.431 147.55 70.1959 147.433 70.0783 147.55C68.9026 148.256 69.0202 149.549 69.8432 150.254C69.9607 150.372 70.0783 150.372 70.1959 150.489C70.7837 150.842 71.7242 150.96 72.5472 150.842C72.8999 150.842 73.3702 150.842 73.6053 150.96L85.3622 156.838C85.7149 156.956 85.8325 157.191 85.8325 157.426C85.8325 158.014 86.3028 158.719 87.1257 159.189Z"
            fill="#CCCCCC"/>
        <path
            d="M97.9439 156.368C97.9439 155.898 98.2966 155.193 98.7669 154.957L99.4723 154.487C99.7074 154.37 99.9426 154.37 100.06 154.487L79.9559 142.848C79.8383 142.73 79.6032 142.73 79.3681 142.848L78.6627 143.318C78.1924 143.553 77.8397 144.141 77.8397 144.729C77.8397 144.964 77.9573 145.199 78.0748 145.199L98.179 156.838C98.0615 156.838 97.9439 156.603 97.9439 156.368Z"
            fill="#BD8B55"/>
        <path
            d="M90.5353 152.136C90.5353 151.549 90.888 150.961 91.3583 150.608L92.1813 150.138C92.4164 150.02 92.6515 150.138 92.7691 150.255C92.7691 150.138 92.7691 150.138 92.6515 150.02L79.719 142.496C79.6014 142.378 79.3663 142.378 79.1312 142.613L78.3082 143.084C77.8379 143.319 77.4852 144.024 77.4852 144.612C77.4852 144.847 77.6028 145.082 77.7203 145.2L90.5353 152.607C90.6529 152.724 90.888 152.607 90.888 152.607C90.7704 152.489 90.888 152.607 90.7705 152.489C90.4177 152.372 90.5353 152.254 90.5353 152.136Z"
            fill="#E34248"/>
        <path d="M99.2329 160.835L96.4113 160.718L94.7653 159.777V156.838L96.4113 157.779L99.2329 157.896V160.835Z"
              fill="#4F4F4F"/>
        <path d="M99.2313 157.898L106.05 154.018V156.957L99.2313 160.837V157.898Z" fill="#585858"/>
        <path d="M96.4114 157.778L94.7655 156.838L102.878 152.135L106.052 154.016L99.2331 157.896L96.4114 157.778Z"
              fill="#373737"/>
        <path
            d="M102.059 158.719C101.589 158.954 101.236 158.719 101.236 158.248C101.236 157.778 101.589 157.073 102.059 156.837L102.765 156.367C103.235 156.132 103.588 156.367 103.588 156.837C103.588 157.308 103.235 158.013 102.765 158.248L102.059 158.719Z"
            fill="#BD8B55"/>
        <path d="M29.3988 177.764L3.76882 162.833L1.65259 155.073L27.2825 170.005L29.3988 177.764Z" fill="#A32126"/>
        <path d="M27.2827 170.005L1.65277 155.074L18.3475 145.434L43.9775 160.365L27.2827 170.005Z" fill="#C93B40"/>
        <path d="M43.9775 160.365L41.9788 170.476L29.399 177.765L27.2828 170.005L43.9775 160.365Z" fill="#C1272D"/>
        <path d="M27.2827 170.004L1.65277 155.072L3.65143 144.961L29.2814 159.893L27.2827 170.004Z" fill="#C1272D"/>
        <path d="M29.2799 159.894L3.64996 144.962L16.2298 137.791L41.9773 152.604L29.2799 159.894Z" fill="#FF3B42"/>
        <path d="M41.9762 152.604L43.9748 160.364L27.2801 170.004L29.2788 159.894L41.9762 152.604Z" fill="#FF3B42"/>
        <path d="M17.1732 166.124L13.0583 163.773L12.3529 161.304L16.4678 163.655L17.1732 166.124Z" fill="#999999"/>
        <path d="M16.4678 163.656L12.3529 161.304L13.0583 158.012L17.1732 160.364L16.4678 163.656Z" fill="white"/>
        <path
            d="M27.6336 144.257L21.7552 140.847C21.0498 140.494 20.462 140.494 20.1092 140.73L18.9336 141.435C18.4633 141.67 18.2281 142.258 18.2281 142.963V145.785L19.7565 146.726L20.9322 146.02V143.669L26.4579 146.843C26.9282 147.078 27.2809 147.666 27.2809 148.254V151.076L28.8093 152.016L29.985 151.311V148.489C29.985 146.843 28.9269 144.962 27.6336 144.257Z"
            fill="#4D4D4D"/>
        <path
            d="M26.4589 144.962C27.7522 145.668 28.8103 147.549 28.8103 149.077V151.899L27.2819 150.958V148.137C27.2819 147.666 26.9292 146.961 26.4589 146.726L20.5805 143.316C20.1102 143.081 19.7575 143.316 19.7575 143.787V146.608L18.2291 145.668V142.846C18.2291 141.318 19.2872 140.73 20.5805 141.553L26.4589 144.962Z"
            fill="#192936"/>
        <path
            d="M84.6588 136.734L95.3575 142.848C95.3575 142.848 95.4751 142.965 95.4751 143.083C95.4751 143.318 95.3575 143.671 95.1224 143.788C95.0048 143.906 94.8873 143.906 94.8873 143.788L84.1885 137.675L82.7777 137.204L81.7196 136.146L82.3074 135.206L83.7183 135.676L84.6588 136.734Z"
            fill="#999999"/>
        <path
            d="M102.765 145.67L95.4756 142.025C95.358 141.908 95.1229 141.908 94.7702 142.143C94.2999 142.378 93.9472 143.083 93.9472 143.671C93.9472 143.906 94.0647 144.142 94.1823 144.259L101.001 148.727C100.766 148.609 100.649 148.256 100.649 147.904C100.649 147.081 101.236 146.14 101.942 145.67C102.177 145.552 102.53 145.552 102.765 145.67Z"
            fill="#FF3B42"/>
        <path
            d="M103.117 146.492C103.117 145.669 102.529 145.316 101.823 145.787C101.118 146.139 100.53 147.198 100.53 148.02C100.53 148.843 101.118 149.196 101.823 148.726C102.529 148.256 103.117 147.315 103.117 146.492Z"
            fill="#A32126"/>
        <path d="M81.6013 136.146L82.8945 137.087L83.4824 135.911L82.1891 135.206L81.6013 136.146Z" fill="#E6E6E6"/>
        <path
            d="M83.6007 137.321L82.8952 137.086L83.6007 135.91L84.1885 136.381C84.4236 136.616 84.4236 136.851 84.3061 137.086C84.1885 137.321 83.8358 137.439 83.6007 137.321Z"
            fill="#B3B3B3"/>
    </svg>

)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
