import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="128" height="60" viewBox="0 0 128 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4027_3757)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M30 60C46.5678 60 60 46.5682 60 30C60 13.4318 46.5685 0 30 0C13.4315 0 0 13.4318 0 30C0 46.5682 13.4315 60 30 60Z"
                          fill="#A70B52"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M37.3055 40.5571H31.093V17.0768H28.7891V42.8755H31.093V42.8595H37.3055V40.5571Z"
                          fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M52.8111 30.9592H37.341V28.656H55.077C55.1006 29.1014 55.112 29.5476 55.112 29.9991C55.112 43.8696 43.8682 55.1129 29.9989 55.1129C16.1296 55.1129 4.88574 43.8696 4.88574 29.9991C4.88574 16.1287 16.1296 4.88538 29.9996 4.88538C30.3653 4.88538 30.7301 4.89298 31.0927 4.90894L31.0897 7.19546C30.7266 7.1787 30.3631 7.17034 29.9996 7.17038C17.3921 7.17038 7.17069 17.3913 7.17069 29.9991C7.17069 42.6077 17.3914 52.8279 29.9996 52.8279C42.2635 52.8279 52.2684 43.1581 52.805 31.0253L52.8111 30.9592Z"
                          fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M20.9635 24.4284C21.9144 24.4284 22.8265 24.0504 23.4989 23.3775C24.1713 22.7045 24.5491 21.7918 24.5491 20.8402C24.5491 19.8885 24.1713 18.9758 23.4989 18.3029C22.8265 17.63 21.9144 17.252 20.9635 17.252C20.0125 17.252 19.1005 17.63 18.4281 18.3029C17.7557 18.9758 17.3779 19.8885 17.3779 20.8402C17.3779 21.7918 17.7557 22.7045 18.4281 23.3775C19.1005 24.0504 20.0125 24.4284 20.9635 24.4284Z"
                          fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M76 45.2248H97.8219V38.7969H84.0127V15.7759H76V45.2248ZM124.188 17.8494C121.686 15.9586 118.465 15 114.62 15C110.055 15 106.242 16.3677 103.591 18.9522C100.822 21.6536 99.3571 25.633 99.3571 30.46C99.3571 35.6161 100.906 39.9683 103.719 42.7098C105.926 44.8625 108.869 46 112.235 46C119.626 46 121.326 41.6787 121.326 41.6787V45.2241H128V28.5918H114.715V33.7962H120.83C120.83 33.7962 120.889 40.348 114.578 40.348C112.974 40.348 111.546 39.7804 110.447 38.7085C108.735 37.0397 107.829 34.228 107.829 30.5815C107.829 24.543 110.29 20.9381 114.41 20.9381C116.983 20.9381 118.918 22.4794 119.414 24.8155H127.913C127.472 21.4219 126.517 19.6526 124.188 17.8494Z"
                          fill="#808183"/>
            </g>
            <defs>
                    <clipPath id="clip0_4027_3757">
                            <rect width="128" height="60" fill="white"/>
                    </clipPath>
            </defs>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
