import React, {useState} from "react";
import {StyledCallUsContainer, StyledHeader} from "./Header.styles";
import {Button, Col, Grid, Modal, Row} from "antd";
import {
    AdaptiveLogoIcon,
    AdaptiveMenuClose,
    AdaptiveMenuOpen,
    AddressAdaptiveIcon,
    LogoIcon,
    PhoneIcon
} from "../../assets";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ROUTE_PATH} from "../../constants";
const {useBreakpoint} = Grid;
export const Header = () => {
    const location = useLocation();
    const {pathname} = location;

    const [showAdaptiveMenu, setShowAdaptiveMenu] = useState(false)

    const navigate = useNavigate();

    const screens = useBreakpoint();

    const handleNavigate = (to) => {
        navigate(to)
        setShowAdaptiveMenu(false)
    }

    const handleActiveRoute = (path) => {

        return path === pathname ? "active" : ""
    }

    return (
        <StyledHeader $showAdaptiveMenu={showAdaptiveMenu}>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }} style={{height: "100%"}}>
                <Row align="middle" justify="space-between" style={{height: "100%"}}>
                    <AdaptiveLogoIcon className="adaptiveLogo" onClick={() => handleNavigate(ROUTE_PATH.MAIN)}/>
                    <LogoIcon className="normalLogo" onClick={() => handleNavigate(ROUTE_PATH.MAIN)}/>

                    <Col span={9} className="menu-items">
                        <Row justify="space-between">
                            <span className={`routeLink main ${handleActiveRoute(ROUTE_PATH.MAIN)}`} onClick={() => handleNavigate(ROUTE_PATH.MAIN)}>Home</span>
                            <span className={`routeLink brands ${handleActiveRoute(ROUTE_PATH.BRANDS)}`} onClick={() => handleNavigate(ROUTE_PATH.BRANDS)}>Brands</span>
                            <span className={`routeLink area ${handleActiveRoute(ROUTE_PATH.AREA)}`} onClick={() => handleNavigate(ROUTE_PATH.AREA)}>Area</span>
                            <span className={`routeLink contacts ${handleActiveRoute(ROUTE_PATH.CONTACTS)}`}
                                  onClick={() => handleNavigate(ROUTE_PATH.CONTACTS)}>Contacts</span>
                        </Row>
                    </Col>

                    <Row align="middle" style={{position: "relative"}}>
                        <Link to="tel:(425) 880-9100">
                            <StyledCallUsContainer>
                                <PhoneIcon/>
                                <span className="phoneNumber">(425) 880-9100</span>
                            </StyledCallUsContainer>
                        </Link>

                        <Button className="adaptive-menu" onClick={() => setShowAdaptiveMenu(!showAdaptiveMenu)}>
                            {showAdaptiveMenu ? <AdaptiveMenuClose /> : <AdaptiveMenuOpen />}

                        </Button>
                    </Row>
                </Row>
            </Col>
            <Modal
                className="adaptive-menu-content"
                open={showAdaptiveMenu}
                width={"100%"}
                footer={() => {
                }}
                onOk={() => setShowAdaptiveMenu(false)}
                onCancel={() => setShowAdaptiveMenu(false)}
            >
                <Row justify="center">
                    <Col xs={24} sm={4}>
                        <span className={`routeLink main`} onClick={() => handleNavigate(ROUTE_PATH.MAIN)}>Home</span>
                    </Col>
                    <Col xs={24} sm={4}>
                        <span className={`routeLink brands`}
                              onClick={() => handleNavigate(ROUTE_PATH.BRANDS)}>Brands</span>
                    </Col>
                    <Col xs={24} sm={4}>
                        <span className={`routeLink area `} onClick={() => handleNavigate(ROUTE_PATH.AREA)}>Area</span>
                    </Col>
                    <Col xs={24} sm={4}>
                        <span className={`routeLink contacts`}
                              onClick={() => handleNavigate(ROUTE_PATH.CONTACTS)}>Contacts</span>
                    </Col>
                </Row>
            </Modal>
        </StyledHeader>
    )
}