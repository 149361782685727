import Icon from "@ant-design/icons";


const Svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.0266 10H3.97338C3.44925 10 3 10.4489 3 10.9726V39.0274C3 39.5511 3.44925 40 3.97338 40H47.0266C47.5508 40 48 39.5511 48 39.0274V10.9726C47.9251 10.4489 47.4759 10 47.0266 10ZM46.0532 38.0549H4.87188V11.8703H46.0532V38.0549Z" fill="white"/>
    <path d="M34.822 36.1845H42.3095C42.8336 36.1845 43.2829 35.7357 43.2829 35.212V14.7132C43.2829 14.1895 42.8336 13.7406 42.3095 13.7406H34.822C34.2978 13.7406 33.8486 14.1895 33.8486 14.7132V35.2868C33.9235 35.7357 34.2978 36.1845 34.822 36.1845ZM35.7953 15.611H41.411V34.3142H35.7953V15.611Z" fill="white"/>
    <path d="M9.58902 35.2868C10.1131 35.2868 10.5624 34.8379 10.5624 34.3142V15.611C10.5624 15.0873 10.1131 14.6384 9.58902 14.6384C9.06489 14.6384 8.61564 15.0873 8.61564 15.611V34.3142C8.61564 34.8379 9.06489 35.2868 9.58902 35.2868Z" fill="white"/>
  </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
