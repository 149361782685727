import styled from 'styled-components'

export const StyledMainContainer = styled.div`
    background-color: #fff !important;
    background: #fff !important;
    
    .ant-form-item-control{
        .ant-input{
            border: none;

        }
    }
  
    .ant-select{
      &:hover{
        .ant-select-selector{
          border-color: red !important;
        }
      }
    }
    `