import Icon from "@ant-design/icons";


const Svg = () => (
    <svg width="160" height="55" viewBox="0 0 160 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.04307 21C4.26379 21 6.30687 21 8.43877 21C9.50472 21 10.1265 21.7066 10.0377 22.7665C9.86003 25.0631 9.68237 27.3596 9.50471 29.6561H20.9637C21.2302 26.8296 21.4078 23.9148 21.6743 21.0883H29.7578L27.8035 45.2901C27.8035 45.555 27.8035 45.7317 27.3594 45.7317C24.7833 45.7317 22.2961 45.7317 19.6312 45.7317C19.8977 42.1103 20.253 38.4888 20.5195 34.8674H9.06057C8.79408 38.4888 8.43877 42.0219 8.17228 45.6434H0L2.04307 21Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M89.1846 45.7321C89.5399 39.9908 89.984 34.1612 90.3394 28.4199C94.6032 27.9782 97.5345 27.6249 100.999 27.6249C105.973 27.5366 112.28 27.095 112.28 33.0129L111.481 45.7321H104.019L104.819 33.5429C104.907 31.423 102.598 31.423 100.91 31.5113C99.8441 31.5997 98.7781 31.688 97.6233 31.7763C97.3569 36.4577 97.0015 41.139 96.735 45.8204H89.1846V45.7321Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M71.684 38.2242C71.684 40.5207 74.3489 41.5807 76.3031 41.934C79.5898 42.5523 83.5871 41.7573 87.0515 41.3157L86.4297 45.0254C82.077 46.262 73.1941 46.6153 68.4862 44.3188C64.3112 42.2873 63.1564 37.6943 64.933 33.8078C66.8873 29.4798 71.684 27.4483 76.392 27.2716C79.9451 27.1833 83.4983 28.0666 85.719 30.1864C87.5845 31.953 88.4727 34.6911 87.8509 38.0476C82.7877 38.2242 76.7473 38.2242 71.684 38.2242ZM81.3664 34.6911C81.3664 32.1296 79.3233 30.893 76.9249 30.9814C74.7042 30.9814 72.3058 32.218 72.0393 34.6911H81.3664Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M143.635 38.2242C143.635 40.5207 146.3 41.5807 148.254 41.934C151.541 42.5523 155.538 41.7573 159.003 41.3157L158.381 45.0254C154.028 46.262 145.145 46.6153 140.437 44.3188C136.262 42.2873 135.108 37.6943 136.884 33.8078C138.838 29.4798 143.635 27.4483 148.343 27.2716C151.896 27.1833 155.449 28.0666 157.67 30.1864C159.536 31.953 160.424 34.6911 159.802 38.0476C154.739 38.2242 148.698 38.2242 143.635 38.2242ZM153.318 34.6911C153.318 32.1296 151.275 30.893 148.876 30.9814C146.655 30.9814 144.257 32.218 143.991 34.6911H153.318Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M62.6257 32.0404C59.6056 31.5104 53.7428 30.6272 50.9003 31.4221C49.2125 32.0404 51.6109 33.1003 52.1439 33.277C54.5423 34.3369 57.6513 35.3085 59.9609 36.6334C64.2247 38.4883 65.0241 44.0529 57.5625 45.3779C53.4763 46.1728 46.0147 45.6428 41.5732 45.1129L41.9286 41.0498C47.5248 41.8448 49.2125 41.8448 53.3875 41.8448C54.0093 41.8448 55.0753 41.0498 54.187 40.5198C50.8115 38.93 45.5705 37.6934 43.1722 35.3085C41.3067 33.4536 41.6621 30.7155 43.794 29.2139C48.2354 26.2108 58.6284 27.2707 63.0699 28.154L62.6257 32.0404Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M134.576 32.0404C131.556 31.5104 125.693 30.6272 122.85 31.4221C121.163 32.0404 123.561 33.1003 124.094 33.277C126.492 34.3369 129.602 35.3085 131.911 36.6334C136.175 38.4883 136.974 44.0529 129.513 45.3779C125.427 46.1728 117.965 45.6428 113.523 45.1129L113.879 41.0498C119.475 41.8448 121.163 41.8448 125.338 41.8448C125.96 41.8448 127.025 41.0498 126.137 40.5198C122.762 38.93 117.521 37.6934 115.122 35.3085C113.257 33.4536 113.612 30.7155 115.744 29.2139C120.186 26.2108 130.579 27.2707 135.02 28.154L134.576 32.0404Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M32.3334 27.713H40.328C39.8839 33.7192 39.3509 39.6372 38.9067 45.6434H30.9121C31.3563 39.6372 31.8004 33.6309 32.3334 27.713Z"
              fill="#00AE9C"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M32.5117 25.7692C32.6005 24.1793 32.7782 22.5895 32.867 21.0879H39.0851C41.3946 21.0879 40.684 22.6778 40.4175 25.8576H32.5117V25.7692Z"
              fill="#00AE9C"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
