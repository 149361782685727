import React from "react";
import {StyledSection1Container} from "./Section1.styles";
import {Col, Row} from "antd";
import {MyCard} from "../../../common/MyCard/MyCard";
import {MyButton} from "../../../common/MyButton/MyButton";
import {CheckboxIcon} from "../../../../assets";

export const Section1 = ({setIsModalOpen}) => {

    const advantages = [
        "Flexible appointments",
        "Best warranty on parts and labor",
        "Emergency service available"
    ]

    return(
        <StyledSection1Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }} lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row>
                    <MyCard className="advantagesCard">
                        <h1 className="advantagesTitle">A-FIX Service</h1>
                        {advantages.map((item, index) => (
                            <Row key={index + "-section1-item"} className="advantagesItem" align="middle">
                                <CheckboxIcon/>
                                <span className="advantagesText">{item}</span>
                            </Row>
                        ))}
                        <MyButton style={{marginTop: 30}} type="primary" onClick={() => setIsModalOpen(true)}>Schedule Appointment</MyButton>
                    </MyCard>
                </Row>
            </Col>
        </StyledSection1Container>
    )
}