export const LightTheme = {
    token: {
        colorPrimary: "#1D1D1F",
        colorInfo: "#4472E4",
        secondaryColor: "#FFFFFF",
        fontFamily: "Inter"
    },
    components: {
        Select: {
            hoverBorderColor: "#1D1D1F",
            activeBorderColor: "#1D1D1F",
        },
    },
    colorText: "#1D1D1F"
}