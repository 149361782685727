import styled from 'styled-components'

import bg from '../../../../assets/images/section3/image-2.jpg'
import bgAdaptive from '../../../../assets/images/section3/1440-img.jpg'
import imageSoSmall from '../../../../assets/images/section3/image-so-small.jpg'
import bg1440 from '../../../../assets/images/section3/1440-img.jpg'
import bg768 from '../../../../assets/images/section3/768-img.jpg'
import {LightTheme} from "../../../../assets";

export const StyledSection3Container = styled.div`
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: top;

    @media (min-width: 1920px) {
        background-size: cover;
    }

    @media (max-width: 834px) {
        background-image: url(${bgAdaptive}) !important;

    }

    @media (max-width: 400px) {
        background-image: url(${imageSoSmall}) !important;
    }

    .ant-btn {
        background-color: #1D1D1F;

        &:hover {
            background-color: #2F2E2E !important;
        }

        @media (max-width: 450px) {
            margin-bottom: 16px;
        }
    }

    .advantagesCard {
        width: 529px;
        margin-top: 80px;
        margin-bottom: 170px;

        .advantagesTitle {
            margin-top: 0;
            font-size: 48px;
            font-weight: bold;

            @media (max-width: 834px) {
                font-size: 32px;
            }

            @media (max-width: 450px) {
                font-size: 28px !important;
                line-height: 32px !important;
            }
        }


        @media (max-width: 400px) {
            .ant-btn {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        .advantagesText {
            font-size: 20px;
            line-height: 32px;
            text-align: justify;
            color: ${LightTheme.colorText};

            @media (max-width: 768px) {
                font-size: 16px;
                line-height: 24px;
            }

            @media (max-width: 450px) {
                font-size: 18px;
                line-height: 28px;
            }
        }

        @media (max-width: 768px) {
            width: 380px;
            padding: 40px !important;
        }

        @media (max-width: 450px) {

            position: relative;
            top: 90px;
            margin-bottom: 80px;
            padding: 19px !important;
        }

        @media (max-width: 400px) {

            position: relative;
            top: 100px;
            margin-bottom: 80px;
            padding: 19px !important;
        }


    }

    @media (max-width: 1200px) {
        background-image: url(${bg1440});
    }

    @media (max-width: 1145px) {
        .advantagesCard {
            padding: 40px;
            width: 450px;
            position: relative;
            top: 80px;

            .advantagesTitle {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }

    @media (max-width: 1020px) {
        .advantagesCard {
            padding: 30px;
            position: relative;
            top: 80px;
        }
    }

    @media (max-width: 835px) {
        .advantagesCard {
            padding: 30px;
            width: 376px;
            top: 0px;

            .advantagesTitle {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }

    @media (max-width: 623px) {
        .advantagesCard {
            top: 60px;
        }
    }


    @media (max-width: 768px) {
        background-image: url(${bg768});
        background-repeat: no-repeat;

        .advantagesText {
            font-size: 16px !important;
            line-height: 24px !important;
        }
    }
`