import Icon from "@ant-design/icons";


const Svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16.0001" r="16" fill="#2F2E2E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11.7625 9.64298C12.9375 8.54774 14.35 8.00012 16 8.00012C17.65 8.00012 19.0625 8.54774 20.2375 9.64298C21.4125 10.7382 22 12.0596 22 13.6073C22 14.8454 21.5 16.3334 20.5 18.0716C19.5 19.8097 18.5 21.2382 17.5 22.3573L16 24.0001C15.825 23.8335 15.6063 23.6013 15.3438 23.3037C15.0812 23.0061 14.6063 22.4287 13.9188 21.5716C13.2312 20.7144 12.625 19.8811 12.1 19.0716C11.575 18.262 11.0938 17.3454 10.6562 16.3216C10.2187 15.2977 10 14.393 10 13.6073C10 12.0596 10.5875 10.7382 11.7625 9.64298ZM14.5614 15.4287C14.9591 15.8096 15.4386 16.0001 16 16.0001C16.5614 16.0001 17.0351 15.8037 17.4211 15.4108C17.807 15.018 18 14.5477 18 14.0001C18 13.4525 17.807 12.9823 17.4211 12.5894C17.0351 12.1965 16.5614 12.0001 16 12.0001C15.4386 12.0001 14.9649 12.1965 14.5789 12.5894C14.193 12.9823 14 13.4525 14 14.0001C14 14.5477 14.1871 15.0239 14.5614 15.4287Z"
              fill="#FFFFFF"/>
    </svg>
)

export const MyIcon = (props) => <Icon component={Svg} {...props}/>
