import styled from 'styled-components'

export const StyledSection2Container = styled.div`
    
  .textBlock{
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 60px;
    
    @media (max-width: 450px) {
      margin-top: 60px;
    margin-bottom: 80px;
    }
    
    .title{
      font-size: 48px;
      line-height: 56px;
      font-weight: bold;
      
      @media(max-width: 862px){
        font-size: 40px;
        line-height: 48px;
      }
      
      @media(max-width: 636px){
        text-align: center;
      }
      
      @media (max-width: 450px){
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
    }
    
    .advantagesItemsContainer{
      @media (max-width: 400px){
      }
    }
    
    .subtitle{
      font-size: 20px;
      line-height: 40px;
      text-align: center;

      @media(max-width: 862px){
        font-size: 16px;
        line-height: 24px;
      }

      @media (max-width: 450px){
        font-size: 18px;
        line-height: 28px;
      }
    }
    
    
  }
  
  .couponContainer{
    svg {
      width: 600px;
      padding-bottom: 12px;
      
      &:hover{
        cursor: pointer;
      }
    }

    @media (min-width: 1651px) and (max-width: 1800px) {
      svg {
        width: 550px !important;
      }
    }

    @media (min-width: 1501px) and (max-width: 1650px) {
      svg {
        width: 500px !important;
      }
    }

    @media (min-width: 1349px) and  (max-width: 1500px) {
      svg {
        width: 450px !important;
      }
    }

    @media (max-width: 1068px) and (max-width: 1348px) {
      svg {
        width: 350px !important;
      }
    }

    @media (max-width: 1068px) {
      .anticon {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100% !important;

        svg {
          width: 100% !important;
        }
      }
    }
  }
  
  .ant-image-img{
    @media (max-width: 450px){
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .advantagesTitle{
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    
    @media (min-width: 769px) and (max-width: 991px){
      font-size: 20px;
    }

    @media (max-width: 991px){
      font-size: 18px;
      line-height: 24px;
    }

    @media(max-width: 450px){
      font-size: 24px;
      line-height: 32px;
    }
  }
  
  .advantagesText{
    font-size: 18px;
    line-height: 28px;

    @media (min-width: 769px) and (max-width: 991px){
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 991px){
      font-size: 14px;
      line-height: 20px;
    }

    @media(max-width: 450px){
      font-size: 18px;
      line-height: 28px;
    }
  }
  
  .bold-text{
    font-weight: bold;
  }
  
  .scheduleAppointmentContainer{
    margin-top: 60px;
    margin-bottom: 140px;

    @media (max-width: 450px) {
      margin-top: 30px;
      margin-bottom: 90px;

    }
  }
  
  @media(max-width: 1535px){
    .custom-br{
      display: none;
    }
  }
`