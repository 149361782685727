import React, {createRef, useState} from "react";
import {StyledArea} from "./index.styles";
import {Form, Grid, message} from "antd";
import {StyledSection8Container} from "../../components/pages/Main/Section8/Section8.styles";
import {Section10, SectionArea2} from "../../components";
import {MyButton} from "../../components/common/MyButton/MyButton";
import {axiosConfig} from "../../config/axios.config";

import brandListJson from '../../assets/data/arrayOfBrands.json'
import servicesListJson from "../../assets/data/services.json";
import {MyModal} from "../../components/common/MyModal/MyModal";
const {useBreakpoint} = Grid
export const Area = () => {
  const [form] = Form.useForm();

  const servicesList = servicesListJson
  const brandList = brandListJson

  const screens = useBreakpoint()

  const recaptchaRef = createRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleReCaptchaChange = (value) => {
    console.log("handleReCaptchaChange value",value)
  }

  const onFinishForm = (values) => {
    setLoading(true)
    const recaptchaVal = recaptchaRef.current.getValue();


    form.validateFields().then(() => {

          if (recaptchaVal) {
            return axiosConfig.post("/api/a-fix/client-lead", {...values}).then((res) => {
              form.resetFields()
              setLoading(false)
              setIsModalOpen(!isModalOpen)
              return message.success({content: "Submitted"})
            }).catch((res) => {
              setLoading(false)
            })
          } else {
            setLoading(false)
            return message.error("Recaptcha error")
          }
        }
    )
  }


  return (
    <StyledArea>
      <SectionArea2 setIsModalOpen={setIsModalOpen}/>
      
      <MyModal screens={screens} form={form} loading={loading} isModalOpen={isModalOpen} handleOk={handleOk}
               handleCancel={handleCancel}
               onFinishForm={onFinishForm} servicesList={servicesList} brandList={brandList} recaptchaRef={recaptchaRef}
               handleReCaptchaChange={handleReCaptchaChange}/>
    </StyledArea>
  )
}