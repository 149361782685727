import React from "react";
import {StyledCarousel, StyledSection9Container} from "./Section9.styles";
import {Col, Row, Grid, Image} from "antd";

import DefaultImage from '../../../../assets/images/section9/DefaultImage.png'
import LucyImage from '../../../../assets/images/section9/LucyImage.png'
import SarahImage from '../../../../assets/images/section9/SarahImage.png'
import LiliaImage from '../../../../assets/images/section9/LiliaImage.png'
import LysaImage from '../../../../assets/images/section9/LysaImage.png'

const {useBreakpoint} = Grid;

export const Section9 = () => {

  const screens = useBreakpoint()
  
  const reviews = [
    {
      icon: LucyImage,
      userName: "Lucy Taylor",
      comment: "I had a great experience with this company. Sergio was knowledgeable, friendly, polite. Their communication was excellent, keeping me advised of when they'd be coming and explaining everything while here. I needed an ice maker replaced and it was done efficiently. Even when there was a delay in getting the part, Sergio went out of his way to get it and finish the job as quickly as possible."
    },
    {
      icon: SarahImage,
      userName: "Sarah Thibeault",
      comment: "This company has come to our rental to fix appliances a few times now! I appreciate their understanding of how inconvenient it is to be without an appliance and always try to get parts and schedule a visit as soon as possible. We have had Sergio as our technician each time and he is great! Very polite and very quick to diagnose and repair issues."
    },
    {
      icon: LiliaImage,
      userName: "Lilia Anderson",
      comment: "Thank you Aleksey for coming out so quickly to help us out with our washing machine. It was a pleasure to meet you. He was in and out, very professional, polite, courteous, and did the job so fast. If everyone is like Aleksey in your company I will recommend them over and over. What a great service. Thank you so much. We will definitely use you guys in the future."
    },
    {
      icon: LysaImage,
      userName: "Lysa B",
      comment: "Sergio came today to fix my Washer & he was awesome.  He was very friendly,  quick & efficient.  He also went above & beyond to explain how to deal with any possible future issue that could potentially arise with the draining of the washer. Excellent service, thanks Sergio!"
    },
    {
      icon: DefaultImage,
      userName: "Lorely D.",
      comment: "Ruslan with A-Fix was great to work with on our leaking washing machine. He was on time for both the inspection and the installation, and was quick and thorough with his work. And we were able to do laundry with no leaks! Very thankful."
    },
    {
      icon: DefaultImage,
      userName: "Siddharth Chadha",
      comment: "Our home warranty company connected us with A Fix and Sergio. He was knowledgeable and quick to diagnose issues with water leak from fridge and dishwasher not cleaning properly. Sergio got the replacement parts within a week and fixed the issues for good. Great job here!"
    },
  ]



  const settings = {
      slidesToShow: !screens.md ? 1 : 2,
      slidesToScroll: !screens.md ? 1 : 2,
  }

    return (
        <StyledSection9Container>
            <Col xs= {{
                pull: 1,
                span: 22,
                push: 1,
            }}lg={{
                pull: 3,
                span: 18,
                push: 3,
            }} xl={{
                pull: 4,
                span: 16,
                push: 4,
            }}>
                <Row className="textBlock" style={{flexDirection: "column", marginTop: 50, marginBottom: 30}} align="middle">
                    <h1 className="title">Testimonials</h1>
                    <span className="subtitle">Read reviews about our repair, installation and maintenance services. Our trained experts are {!screens.lg ? null : <br />}  always ready to help! ♥</span>
                </Row>
              <StyledCarousel {...settings} dots={true} >
                {
                  reviews.map((reviewItem, index) => (
                    <div key={index + "-user-review"} className="item">
                      <span className="item_text">{reviewItem.comment}</span>

                      <Row align="middle" className="userInfo">
                        <Image src={reviewItem.icon} preview={false}/>
                        <span>{reviewItem.userName}</span>
                      </Row>
                    </div>
                  ))
                }
              </StyledCarousel>
            </Col>
        </StyledSection9Container>
    )
}