import styled from "styled-components";

export const StyledFooter = styled.div`
  background-color: #1D1D1F;
  padding-top: 24px;
  padding-bottom: 24px;
  
  .adaptive-container{
    display: none;
    
    @media (max-width: 746px) {
      display: flex;
      align-items: center;
      
      .mailContainer{
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .footerLogo{
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }
  
  @media (max-width: 746px){
    .normal-container{
      display: none;
    }
  }

  .mailContainer{
    @media(max-width: 646px){
      text-align: center;
    }
  }
  .phoneContainer{
    @media(max-width: 646px){
      text-align: center;
    }
  }
  
  .mailContainer span, .phoneContainer span{
    margin-left: 16px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #fff;

    @media(max-width: 1346px){
      font-size: 20px;
    font-weight: 500;
      letter-spacing: 0;
    }

    @media (max-width: 1020px){
      font-size: 16px;
    }
    
    @media(max-width: 375px){
      margin-top: 12px;
    }
    
  }
  
  .footerLogo{
    
    @media(max-width: 375px){
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
  
  .documents span{
    font-size: 18px;
    line-height: 24px;
    color: #D0D0D0;
    text-decoration: underline;
    
    
    &:hover{
      cursor: pointer;
    }
    
    @media (max-width: 991px){
      font-size: 14px;
    }
    
    @media(max-width: 768px){
      margin-right: 12px;
    }
  }
  
  .copyright{
    font-size: 18px;
    line-height: 24px;
    color: #D0D0D0;

    @media(max-width: 1040px){
      font-size: 16px;
    }
    
    @media (max-width: 991px){
      font-size: 14px;
    }

    @media (max-width: 646px){
      font-size: 13px;
    }
    
    @media(max-width: 375px){
      margin-top: 24px;
      display: flex;
      text-align: center;
      width: 100%;
    }
  }
`